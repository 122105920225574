import * as React from 'react'
import { Col, Form, Card, Button } from 'react-bootstrap'
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';

class RegistroAssinaturas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        if (this.props.currentStep !== 6) {
            return null;
        }
        return (
            <Card>
                <Card.Header>
                    <h3>{this.props.currentStep} - Assinaturas</h3>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex mb-3">
                        <h5>Dados de Registro</h5>
                        <div className="ms-auto">
                            <Button className="btn btn-info align-self-end" type="button" onClick={this.props.setAssinanteDadosRegistro}>Incluir</Button>
                        </div>
                    </div>
                    {this.props.assinaturaDadosRegistro ? <>
                        <Form.Row>
                            <Form.Group as={Col} sm={12}>
                                {this.props.assinaturaDadosRegistro.length > 0 ? <>
                                    {this.props.assinaturaDadosRegistro.map((a, index) => {
                                        return <AssinaturaForm
                                            key={index} {...a}
                                            i={index}
                                            updateAssinante={this.props.updateAssinanteDadosRegistro}
                                            removeAssinante={this.props.removeAssinanteDadosRegistro} />
                                    })}
                                </> : <></>}
                            </Form.Group>
                        </Form.Row>
                    </> : <></>}
                    <hr />
                    <div className="d-flex mb-3">
                        <h5>Diploma</h5>
                        <div className="ms-auto">
                            <Button className="btn btn-info" type="button" onClick={this.props.setAssinanteDiploma}>Incluir</Button>
                        </div>
                    </div>
                    {this.props.assinaturasDiploma ? <>
                        <Form.Row>
                            <Form.Group as={Col} sm={12}>
                                {this.props.assinaturasDiploma.length > 0 ? <>
                                    {this.props.assinaturasDiploma.map((a, index) => {
                                        return <AssinaturaForm
                                            key={index} {...a}
                                            i={index}
                                            updateAssinante={this.props.updateAssinanteDiploma}
                                            removeAssinante={this.props.removeAssinanteDiploma} />
                                    })}
                                </> : <></>}
                            </Form.Group>
                        </Form.Row>
                    </> : <></>}
                </Card.Body>
            </Card>
        )
    }
}

export default RegistroAssinaturas

class AssinaturaForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Form.Row style={{ marginBottom: 10, padding: 10, borderRadius: 15, borderStyle: 'solid', borderWidth: 1, borderColor: '#ccc' }}>
                <Form.Group as={Col} sm={12} md={8}>
                    <Form.Label>Nome Completo:</Form.Label>
                    <Field
                        type="text"
                        name="nome"
                        title="Nome completo"
                        placeholder="Ex.: José da Silva"
                        maxLength="255"
                        className={`form-control`}
                        onChange={(e) => { this.props.updateAssinante("nome", e.target.valeu, this.props.i) }}
                        value={this.props.nome}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={4}>
                    <Form.Label>CPF:</Form.Label>
                    <Field
                        type="text"
                        title="CPF"
                        name="cpf"
                        placeholder="99999999999"
                        maxLength="255"
                        className={`form-control`}
                        onChange={(e) => { this.props.updateAssinante("cpf", e.target.valeu, this.props.i) }}
                        value={this.props.cpf}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={8}>
                    <Form.Label>Cargo:</Form.Label>
                    <Field
                        type="text"
                        title="Cargo"
                        name="cargo"
                        placeholder="Ex.: Reitor"
                        maxLength="255"
                        className={`form-control`}
                        onChange={(e) => { this.props.updateAssinante("cargo", e.target.valeu, this.props.i) }}
                        value={this.props.cargo}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={4}>
                    <br />
                    <Button className="btn btn-danger" type="button" onClick={() => this.props.removeAssinante(this.props.i)}>Remover</Button>
                </Form.Group>
            </Form.Row>
        )
    }
}