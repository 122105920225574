import * as React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import RegistroNovoCamposDiplomado from './RegistroNovoCamposDiplomado'
import RegistroNovoCamposDadosCurso from './RegistroNovoCamposDadosCurso'
import RegistroNovoCamposLivroRegistro from './RegistroNovoCamposLivroRegistro'
import RegistroNovoCamposFiliacao from './RegistroNovoCamposFiliacao'
import RegistroNovoCamposHistoricoEscolar from './RegistroNovoCamposHistoricoEscolar'
import RegistroNovoCamposIngressoCurso from './RegistroNovoCamposIngressoCurso'
import RegistroNovoCamposAdicionais from './RegistroNovoCamposAdicionais'
import RegistroAssinaturas from "./RegistroAssinaturas";
import { addNovoRegistroRequest } from '../../../../store/thunks'
import { loadMunicipios } from './../../../../store/thunks'
import apiURLs from './../../../../api/apiURLs'
import apiAxios from '../../../../api/apiAxios';
import titleCase from './../titleCase'
import { Formik, Form } from 'formik'
import Swal from 'sweetalert2'
import { cpfMask } from "../../../../util/funcoes";

class RegistroNovoForm extends React.Component {
    static displayName = RegistroNovoForm.name
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            isLoading: true,
            estudante: {},
            ID: String(this.props.rga),
            Nome: '',
            NomeSocial: '',
            Sexo: 'M',
            Nacionalidade: '',
            CPF: '',
            DataNascimento: new Date(),
            NaturalidadeCodigoMunicipio: '',
            NaturalidadeNomeMunicipio: '',
            NaturalidadeUF: '',
            NomeMunicipioEstrangeiro: '',
            RGNumero: '',
            RGOrgaoExpedidor: '',
            RGUF: '',
            OutroDocumentoIdentificacaoTipoDocumento: '',
            OutroDocumentoIdentificacaoIdentificador: '',
            ufs: [],
            municipios: [],
            cursoDados: {},
            CursoID: 0,
            NomeCurso: '',
            Modalidade: 'Presencial',
            Grau: '',
            CursoFormacao: 'Não se aplica.',
            TituloConferido: '',
            ReconhecimentoID: 0,
            Habilitacao: 0,
            CodigoCursoEMEC: 0,
            EnderecoCurso: {},
            AutorizacaoCurso: {},
            graus: [],
            cursoFormacoes: [],
            cursoTitulacoes: [],
            cursoReconhecimentos: [],
            IesEmissoraId: this.props.iesEmissoraId,
            IesRegistradoraId: this.props.iesRegistradoraId,
            Observacao: '',
            livros: [],
            LivroRegistro: '',
            LivroID: 0,
            Processo: '23108.',
            //NumeroFolhaDoDiploma: 1,
            //NumeroSequenciaDoDiploma: 1,
            DataColacao: new Date(),
            DataExpedicao: new Date(),
            DataRegistro: new Date(),
            responsavelRegistro: {
                Nome: sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroNome'),
                CPF: sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF'),
                IDouNumeroMatricula: sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroIDouNumeroMatricula'),
                Cargo: sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCargo'),
            },
            FormaAcesso: 'Convenios',
            TipoIngressoDescricao: '',
            DataIngresso: null,
            DataConclusao: null,
            SituacaoAlunoHistorico: 'Aprovado',
            CargaHorariaCurso: 0,
            CargaHorariaCursoIntegralizada: 0,
            SituacaoENADEIngressante: 'Não informado.',
            SituacaoENADEConcluinte: 'Não informado.',
            SituacaoENADE: 'DispensadoEmRazaoDoCalendarioTrienal',
            DataEmissaoHistorico: new Date(),
            NomeMae: '',
            NomeSocialMae: '',
            SexoMae: 'F',
            NomePai: '',
            NomeSocialPai: '',
            SexoPai: 'M',
            currentStep: 1, // Default is Step 1
            assinaturasDiploma: [],
            assinaturaDadosRegistro: [],
            stepFinal: 7
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeDate = this.handleInputChangeDate.bind(this)
        this.showHabilitacao = this.showHabilitacao.bind(this)
        this.salvarRegistroAPI = this.salvarRegistroAPI.bind(this)
        this._next = this._next.bind(this)
        this._prev = this._prev.bind(this)
        this.isEmpty = this.isEmpty.bind(this)
        this.novoRegistro = React.createRef()
    }
    componentDidMount() {
        this.setDiplomado()
        this.setUFs()
        this.setIESEmissora()
        this.setIESRegistradora()
    }
    setAssinaturas() {
        const urlDadosRegistro = `${apiURLs.GET_ASSINATURAS}/DocumentacaoAcademica`;
        const urlDiploma = `${apiURLs.GET_ASSINATURAS}/DiplomaDigital`;

        apiAxios.get(urlDiploma).then(r => {
            console.log(r.data);
            this.setState({ assinaturasDiploma: r.data });
        }).catch(ex => {
            console.log(ex);
        });

        apiAxios.get(urlDadosRegistro).then(r => {
            console.log(r.data);
            this.setState({ assinaturaDadosRegistro: r.data });
        }).catch(ex => {
            console.log(ex);
        });
    }
    setDiplomado() {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Carregando dados do diplomado.',
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const url = apiURLs.GET_DADOS_ESTUDANTE + this.props.rga
        apiAxios
            .get(url)
            .then((result) => {
                let naturalidadeUF = (result.data.naturalUF?.trim() !== "") ? result.data.naturalUF : "MT";
                this.setState({
                    estudante: result.data,
                    Nome: titleCase(result.data.nome),
                    NomeSocial: titleCase(result.data.nomeSocial),
                    Sexo: result.data.sexo.trim() === "Masculino" ? 'M' : 'F',
                    Nacionalidade: result.data.nacionalidade
                        ? result.data.sexo === 'F' && result.data.nacionalidade.toUpperCase() === 'BRASILEIRO'
                            ? 'Brasileira' : titleCase(result.data.nacionalidade)
                        : '',
                    CPF: cpfMask(result.data.cpf),
                    DataNascimento: new Date(result.data.dataNascimento),
                    NaturalidadeCodigoMunicipio: result.data.naturalMunicipioUID,
                    NaturalidadeNomeMunicipio: result.data.natural?.trim(),
                    NomeMunicipioEstrangeiro: result.data.natural,
                    NaturalidadeUF: naturalidadeUF,
                    RGNumero: result.data.identidade.replace(/[^a-zA-Z0-9]/g, ''),
                    RGOrgaoExpedidor: result.data.identidadeExpeditor,
                    RGUF: result.data.identidadeUF,
                    NomeMae: titleCase(result.data.nomeMae),
                    SexoMae: 'F',
                    NomePai: titleCase(result.data.nomePai),
                    SexoPai: 'M',
                    TipoIngressoDescricao: result.data.tipoIngressoDescricao,
                    DataIngresso: (result.data.dataIngresso != null) ? new Date(result.data.dataIngresso): null,
                    DataConclusao: result.data.ocorrencia === 'EPC' ? new Date(result.data.ocorrenciaData) : null,
                })
                this.setCurso()
                this.setMunicipios(naturalidadeUF)
                this.setEnade()
                this.setDatas();
                this.setFormaAcesso();
                this.setAssinaturas();
                this.setState({ isLoading: false })
                Swal.close()
            })
            .catch((ex) => { console.log(ex) })
    }
    setUFs() {
        const url = apiURLs.GET_UFS
        apiAxios
            .get(url)
            .then((result) => { this.setState({ ufs: result.data }) })
            .catch((ex) => { console.log(ex) })
    }
    setDatas() {
        if ((this.state.estudante.dataColacaoGrau1 !== undefined && this.state.estudante.dataColacaoGrau1 !== null) ||
            (this.state.estudante.dataColacaoGrau2 !== undefined && this.state.estudante.dataColacaoGrau2 !== null)) {
            const DataColacao = (this.state.estudante.dataColacaoGrau1 !== undefined && this.state.estudante.dataColacaoGrau1 !== null) ?
                new Date(this.state.estudante.dataColacaoGrau1) : new Date(this.state.estudante.dataColacaoGrau2);
            const DataConclusao = new Date(DataColacao.getFullYear(), DataColacao.getMonth(), DataColacao.getDate() - 1);
            this.setState({ DataColacao: DataColacao, DataConclusao: DataConclusao })
        }
    }
    setFormaAcesso() {
        if (this.state.TipoIngressoDescricao !== undefined && this.state.TipoIngressoDescricao !== "") {
            if (this.state.TipoIngressoDescricao.includes("TRANSFERENCIA")) {
                this.setState({ FormaAcesso: "Transferencia" })
            } else if (this.state.TipoIngressoDescricao.includes("SISU")) {
                this.setState({ FormaAcesso: "Sisu" })
            } else if (this.state.TipoIngressoDescricao.includes("VESTIBULAR")) {
                this.setState({ FormaAcesso: "Vestibular" })
            } else if (this.state.TipoIngressoDescricao.includes("CONVENIO")) {
                this.setState({ FormaAcesso: "Convenios" })
            }
        }
    }
    setCursosGraus() {
        const url = apiURLs.GET_CURSOS_GRAUS
        apiAxios
            .get(url)
            .then((result) => {
                var grau = this.state.cursoDados.cursoMec.grau;
                const found = result.data.some(el => el.descricao === grau.descricao);
                if (found) {
                    this.setState({ cursosGraus: result.data, Grau: grau.descricao });
                } else {
                    this.setState({ cursosGraus: result.data, Grau: result.data[0].descricao });
                }
            })
            .catch((ex) => { console.log(ex) })
    }
    setIESEmissora() {
        const url = apiURLs.GET_IES + this.state.IesEmissoraId
        apiAxios
            .get(url)
            .then((result) => { this.setState({ iesEmissora: result.data }) })
            .catch((ex) => { console.log(ex) })
    }
    setIESRegistradora() {
        const url = apiURLs.GET_IES + this.state.IesRegistradoraId
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ iesRegistradora: result.data })
            })
            .catch((ex) => { console.log(ex) })
    }
    setLivrosRegistro(campusUID) {
        const url = apiURLs.GET_LIVROS
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ livros: result.data });
                if (result.data && result.data.length > 0) {
                    let estudanteLivro = result.data.filter(g => g.campus.filter(c => c.campusUID === campusUID)[0])[0];
                    if (estudanteLivro === undefined || estudanteLivro === null) {
                        this.setState({ LivroID: result.data[0].livroID, LivroRegistro: result.data[0].nome });
                    } else {
                        this.setState({ LivroID: estudanteLivro.livroID, LivroRegistro: estudanteLivro.nome });
                    }
                }
            })
            .catch((ex) => { console.log(ex) })
    }
    setMunicipios(uf) {
        const url = apiURLs.GET_MUNICIPIOS + uf
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ municipios: result.data  });
                if (this.state.NaturalidadeCodigoMunicipio === undefined || this.state.NaturalidadeCodigoMunicipio == null) {
                    this.setState({ NaturalidadeCodigoMunicipio: result.data[0].municipioUID, NaturalidadeNomeMunicipio: result.data[0].nome}); 
                }else{
                    let municipio = this.state.municipios.filter(x => x.municipioUID === this.state.NaturalidadeCodigoMunicipio);
                    if(municipio.length === 0){
                        this.setState({ NaturalidadeCodigoMunicipio: result.data[0].municipioUID, NaturalidadeNomeMunicipio: result.data[0].nome}); 
                    }
                }
                // if (uf !== undefined && this.state.estudante.NaturalUF !== undefined && this.state.estudante.NaturalUF !== uf) {
                //     console.log('municipio '+ result.data[0].municipioUID);
                //     this.setState({ NaturalidadeCodigoMunicipio: result.data[0].municipioUID  });
                // }
            })
            .catch((ex) => { console.log(ex) })
    }
    removeAssinanteDiploma = (index) => {
        const assinaturasDiploma = this.state.assinaturasDiploma;
        console.log(index);
        delete assinaturasDiploma[index];
        this.setState({ assinaturasDiploma });
    }
    removeAssinanteDadosRegistro = (index) => {
        const assinaturaDadosRegistro = this.state.assinaturaDadosRegistro;
        console.log(index);
        delete assinaturaDadosRegistro[index];
        this.setState({ assinaturaDadosRegistro });
    }
    updateAssinanteDadosRegistro = (campo, valor, index) => {
        const assinaturaDadosRegistro = this.state.assinaturaDadosRegistro;
        assinaturaDadosRegistro[index][campo] = valor;
        this.setState({ assinaturaDadosRegistro });
    }
    updateAssinanteDiploma = (campo, valor, index) => {
        const assinaturasDiploma = this.state.assinaturasDiploma;
        assinaturasDiploma[index][campo] = valor;
        this.setState({ assinaturasDiploma });
    }
    setAssinanteDadosRegistroP = () => {
        const assinaturaDadosRegistro = this.state.assinaturaDadosRegistro;
        let assinante = { nome: "", cargo: "", cpf: "" };
        assinaturaDadosRegistro.push(assinante);
        this.setState({ assinaturaDadosRegistro });
    }
    setAssinanteDiplomaP = () => {
        const assinaturasDiploma = this.state.assinaturasDiploma;
        let assinante = { nome: "", cargo: "", cpf: "" };
        assinaturasDiploma.push(assinante);
        this.setState({ assinaturasDiploma });
    }
    setCurso() {
        const url = apiURLs.GET_CURSO_POR_SIGA_ID + this.state.estudante.codCurso;
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({
                    cursoDados: result.data,
                    CursoID: result.data.cursoID,
                    NomeCurso: titleCase(result.data.nome),
                    CodigoCursoEMEC: result.data.cursoMec.codigoEmec,
                    EnderecoCurso: result.data.enderecoCurso,
                    AutorizacaoCurso: result.data.autorizacao,
                })
                if (result.data.cursoMec.nome !== undefined && result.data.cursoMec.nome !== null && result.data.cursoMec.nome !== "") {
                    this.setState({ NomeCurso: titleCase(result.data.cursoMec.nome) });
                }
                this.setCursoFormacoes()
                this.setCursoReconhecimentos()
                this.setCursoTitulacoes()
                this.setLivrosRegistro(result.data.cursoMec.campus.campusID)
                this.setCursosGraus()
            })
            .catch((ex) => { console.log(ex) })
    }
    setCursoFormacoes() {
        const url = apiURLs.GET_CURSO_FORMACOES + this.state.CursoID
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ cursoFormacoes: result.data })
                if (result.data && result.data.length > 0) {
                    this.setState({ CursoFormacao: result.data[0] ? result.data[0].formacao : '' });
                }
            })
            .catch((ex) => {
                console.log(ex)
            })
    }
    setCursoReconhecimentos() {
        /* const url = apiURLs.GET_CURSO_RECONHECIMENTOS + this.state.CursoID
        apiAxios
            .get(url)
            .then((result) => {
                //console.log("GET_CURSO_RECONHECIMENTOS: "+ result);
                //console.log("GET_CURSO_RECONHECIMENTOS: "+ JSON.stringify(result.data));
                this.setState({ cursoReconhecimentos: result.data })
                if (result.data && result.data.length > 0) {
                    this.setState({ ReconhecimentoID: result.data[0].reconhecimentoID, Habilitacao: result.data[0].habilitacao });
                }
            })
            .catch((ex) => { console.log(ex) }) */
        const url2 = '/api/reconhecimento/cursos/' + this.state.CursoID
        apiAxios
            .get(url2)
            .then((result) => {
                if (result.data && result.data.length > 0) {
                    var reconhecimentosOrdenadosPorDataRecente = result.data.sort(function (a, b) {
                        return new Date(b.atoRegulatorio.data) - new Date(a.atoRegulatorio.data);
                    });
                    this.setState({
                        cursoReconhecimentos: reconhecimentosOrdenadosPorDataRecente,
                        ReconhecimentoID: reconhecimentosOrdenadosPorDataRecente[0].reconhecimentoCursoUid,
                        Habilitacao: reconhecimentosOrdenadosPorDataRecente[0].habilitacao
                    });
                }
            })
            .catch((ex) => { console.log(ex) })
    }
    setCursoTitulacoes() {
        const url = apiURLs.GET_CURSO_TITULACOES + this.state.CursoID
        apiAxios
            .get(url)
            .then((result) => {
                this.setState({ cursoTitulacoes: result.data, TituloConferido: result.data[0].titulacao });
                if (result.data && result.data.length > 0 && this.state.estudante.sexo !== undefined) {
                    const titulo = result.data.find(x => x.sexo === this.state.estudante.sexo);
                    if (titulo) {
                        this.setState({ TituloConferido: titulo.titulacao });
                    }
                }
            })
            .catch((ex) => { console.log(ex) })
    }
    setEnade() {
        const url = apiURLs.GET_DADOS_ENADE_ESTUDANTE + this.props.rga
        apiAxios
            .get(url)
            .then((result) => {
                //console.log("GET_DADOS_ENADE_ESTUDANTE:", result.data);
                this.setState({
                    CargaHorariaCurso: result.data.cargaHorariaCurso,
                    CargaHorariaCursoIntegralizada: result.data.cargaHorariaCursada,
                    SituacaoENADEIngressante: result.data.enadeIngressante ? result.data.enadeIngressante : 'Não informado.',
                    SituacaoENADEConcluinte: result.data.enadeConcluiente ? result.data.enadeConcluiente : 'Não informado.',
                })
                if (this.state.SituacaoENADEConcluinte !== undefined && this.state.SituacaoENADEConcluinte !== null
                    && this.state.SituacaoENADEConcluinte !== "" && this.state.SituacaoENADEConcluinte !== "Não informado.") {
                    if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("estudante particip")) {
                        this.setState({ SituacaoENADE: "ConcluinteParticipante" })
                    } else {
                        if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("em razão do calendário do ciclo avaliativo")) {
                            this.setState({ SituacaoENADE: "DispensadoEmRazaoDoCalendarioTrienal" })
                        } else if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("mec")) {
                            this.setState({ SituacaoENADE: "DispensadoPeloMEC" })
                        } else if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("curso")) {
                            this.setState({ SituacaoENADE: "DispensadoEmRazaoDaNaturezaDoCurso" })
                        } else if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("pessoal")) {
                            this.setState({ SituacaoENADE: "DispensadoPorRazaoDeOrdemPessoal" })
                        } else if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("instrução normativa") || this.state.SituacaoENADEConcluinte.toLowerCase().includes("portaria normativa")) {
                            this.setState({ SituacaoENADE: "DispensadoPorAtoDaInstituicaoDeEnsino" })
                        } else if (this.state.SituacaoENADEConcluinte.toLowerCase().includes("mobilidade") || this.state.SituacaoENADEConcluinte.toLowerCase().includes("fora do país") ||
                            this.state.SituacaoENADEConcluinte.toLowerCase().includes("fora do brasil")) {
                            this.setState({ SituacaoENADE: "DispensadoEmRazaoDeMobilidadeAcademicaNoExterior" })
                        }
                    }
                } else if (this.state.SituacaoENADEIngressante !== undefined && this.state.SituacaoENADEIngressante !== null
                    && this.state.SituacaoENADEIngressante !== "" && this.state.SituacaoENADEIngressante !== "Não informado.") {
                    if (this.state.SituacaoENADEIngressante.toLowerCase().includes("estudante particip")) {
                        this.setState({ SituacaoENADE: "IngressanteParticipante" })
                    }
                }
            })
            .catch((ex) => { console.log(ex) })
    }
    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        let regexpOnlyNumber = /^[0-9\b]+$/ //só numero
        let regexpProcess = /^[0-9 -/.\b]+$/ //só numero e - e . e /
        let regexpOnlyLetter = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$/ //só letra com acentuacao
        let regexpRG = /^[0-9\b a-zA-Z ]+$/ //só letra e numero

        // if (name === 'NumeroFolhaDoDiploma' || name === 'NumeroSequenciaDoDiploma') {
        //     if (regexpOnlyNumber.test(value) || value.length === 0) {
        //         this.setState({ [name]: value })
        //     }
        // } else 
        if (name === 'Nome' || name === 'NomeMae' || name === 'NomePai' || name === 'NomeCurso' || name === 'NomeSocial') {
            if (regexpOnlyLetter.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else if (name === 'Nacionalidade') {
            if (regexpOnlyLetter.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else if (name === 'RGNumero' || name === 'RGOrgaoExpedidor') {
            if (regexpRG.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else if (name === 'Processo') {
            if (regexpProcess.test(value) || value.length === 0) {
                this.setState({ [name]: value })
            }
        } else if (name === "CPF") {
            this.setState({ [name]: cpfMask(value) })
        } else if (name === "NaturalidadeCodigoMunicipio") {
            let municipio = this.state.municipios.filter(x => x.municipioUID == value);
            this.setState({ [name]: value, NaturalidadeNomeMunicipio: municipio[0].nome })
        } else {
            this.setState({ [name]: value })
        }

        if (name === 'NaturalidadeUF') {
            this.setMunicipios(value)
        }
        if (name === 'ReconhecimentoID') {
            this.showHabilitacao(value)
        }
    }
    showHabilitacao(reconhecimentoId) {
        let habilitacao = this.state.cursoReconhecimentos.find((x) => x.reconhecimentoID === Number(reconhecimentoId)).habilitacao
        this.setState({ Habilitacao: habilitacao })
    }
    handleInputChangeDate(date, name) {
        if (date === null) {
            date = new Date()
        }
        this.setState({ [name]: date })
    }
    salvarRegistroAPI = () => {

        let Naturalidade = {};

        if (this.state.Nacionalidade.trim().toLowerCase() == "brasileiro" || this.state.Nacionalidade.trim().toLowerCase() == "brasileira") {
            Naturalidade = {
                CodigoMunicipio: String(this.state.NaturalidadeCodigoMunicipio),
                NomeMunicipio: this.state.NaturalidadeNomeMunicipio?.trim(),
                UF: this.state.NaturalidadeUF,
            }
        } else {
            Naturalidade = {
                NomeMunicipioEstrangeiro: this.state.NomeMunicipioEstrangeiro?.trim(),
            }
        }
        let RG = {
            Numero: this.state.RGNumero,
            OrgaoExpedidor: this.state.RGOrgaoExpedidor,
            UF: this.state.RGUF,
        }
        let outroDocumentoIdentificacao = {
            Identificador: this.state.OutroDocumentoIdentificacaoIdentificador,
            TipoDocumento: this.state.OutroDocumentoIdentificacaoTipoDocumento,
        }

        let Diplomado = {
            ID: this.state.ID,
            Nome: this.state.Nome,
            NomeSocial: this.state.NomeSocial,
            Sexo: this.state.Sexo,
            Nacionalidade: this.state.Nacionalidade,
            CPF: this.state.CPF.replace(/\D/g, ''),
            DataNascimento: this.state.DataNascimento,
            Naturalidade: Naturalidade,
            RG: RG,
            OutroDocumentoIdentificacao: outroDocumentoIdentificacao
        }
        let TituloConferido = {
            Titulo: this.state.TituloConferido.trim(),
            OutroTitulo: this.state.TituloConferido.trim()
        }
        let DadosCurso = {
            NomeCurso: this.state.NomeCurso,
            Modalidade: this.state.Modalidade,
            TituloConferido: TituloConferido,
            GrauConferido: this.state.Grau,
            CursoID: Number(this.state.CursoID),
            ReconhecimentoID: Number(this.state.ReconhecimentoID),
            CursoFormacao: this.state.CursoFormacao,
        }
        let Filiacao = [
            { Nome: this.state.NomeMae, Sexo: this.state.SexoMae },
        ]

        if (this.state.NomePai != null && this.state.NomePai != "" && this.state.NomePai != undefined) {
            Filiacao.push({ Nome: this.state.NomePai, Sexo: this.state.SexoPai });
        }

        let IngressoCurso = {
            Data: this.state.DataIngresso,
            FormaAcesso: this.state.FormaAcesso,
            DataConclusao: this.state.DataConclusao,
        }
        let LivroRegistro = {
            LivroRegistro: this.state.LivroRegistro,
            // NumeroFolhaDoDiploma: String(this.state.NumeroFolhaDoDiploma),
            // NumeroSequenciaDoDiploma: String(this.state.NumeroSequenciaDoDiploma),
            ProcessoDoDiploma: this.state.Processo,
            ResponsavelRegistro: this.state.responsavelRegistro,
            LivroID: Number(this.state.LivroID),
            DataColacaoGrau: this.state.DataColacao,
            DataExpedicaoDiploma: this.state.DataExpedicao,
            DataRegistroDiploma: this.state.DataRegistro,
        }
        let TermoResponsabilidade = {
            Nome: this.state.responsavelRegistro.Nome,
            CPF: this.state.responsavelRegistro.CPF,
            Cargo: this.state.responsavelRegistro.Cargo,
        }
        // Incluido para teste
        if (window.location.host.includes("localhost") === true && (TermoResponsabilidade.Nome == null ||TermoResponsabilidade.Cargo == null )) {
            TermoResponsabilidade.CPF = "26751439016";
            TermoResponsabilidade.Nome = "Analista Teste";
            TermoResponsabilidade.Cargo = "Analista de Sistemas";

            LivroRegistro.ResponsavelRegistro.CPF = "26751439016";
            LivroRegistro.ResponsavelRegistro.Nome = "Analista Teste";
        }
        let dadosRegistro = {
            Diplomado,
            DadosCurso,
            IesEmissoraId: Number(this.state.IesEmissoraId),
            IesRegistradoraId: Number(this.state.IesRegistradoraId),
            Filiacao,
            DataEmissaoHistorico: this.state.DataEmissaoHistorico,
            IngressoCurso,
            LivroRegistro,
            SituacaoAluno: this.state.SituacaoAlunoHistorico,
            SituacaoENADE: this.state.SituacaoENADE,
            Observacao: this.state.Observacao,
            TermoResponsabilidade,
            SegundaVia: false,
            AssinaturasDiploma : this.state.assinaturasDiploma,
            AssinaturasRegistro: this.state.assinaturaDadosRegistro,
        }
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Registro de diploma está sendo salvo.', // add html attribute if you want or remove
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const registroDto = JSON.stringify(dadosRegistro)
        const url = apiURLs.POST_NOVO_REGISTRO
        console.log(url, registroDto);
        apiAxios
            .post(url, registroDto, { headers: { 'Content-Type': 'application/json' } })
            .then((result) => {
                Swal.close()
                Swal.fire({
                    icon: 'success',
                    title: 'Registro cadastrado com sucesso! Redirecionando...',
                    showConfirmButton: false,
                    timer: 4000,
                }).then(this.RedirectToConsultaRegistros())
            })
            .catch((ex) => {
                console.log("ex", ex);
                if (ex.response) {
                    try {
                        console.log(ex.response.data.message);
                        const error = JSON.parse(ex.response.data.message);
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao salvar registro!',
                            html: error.message
                        })
                    } catch (error3) {
                        console.log(error3)
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro ao salvar registro!',
                            html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao salvar registro!',
                        html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se erro persistir entre em contato com o administrador do sistema.'
                    })
                }
            })
    }
    RedirectToConsultaRegistros = () => {
        window.location.href = '/digital/registros'
    }

    _next() {
        let currentStep = this.state.currentStep
        let errors = "";
        if (currentStep == 1) {
            if (this.state.Nome === '') {
                errors += 'Nome é obrigatório; '
            } else if (this.state.Nome.length < 2 || this.state.Nome.length > 255) {
                errors += 'Nome deve ter entre 2 e 255 letras; '
            }

            if (this.state.NomeSocial != '' && this.state.NomeSocial != null && this.state.NomeSocial != undefined) {
                if (this.state.NomeSocial.length < 2 || this.state.NomeSocial.length > 255) {
                    errors += 'Nome Social deve ter entre 2 e 255 letras; '
                }
            }

            if (this.state.CPF === '') {
                errors += 'CPF é obrigatório; '
            } else if (this.state.CPF.replace(/\D+/g, '').length !== 11) {
                errors += 'CPF deve ter 11 dígitos numéricos; '
            }
            if (this.state.Nacionalidade === '') {
                errors += 'Nacionalidade é obrigatório; '
            } else if (this.state.Nacionalidade.length < 2 || this.state.Nacionalidade.length > 255) {
                errors += 'Nacionalidade deve ter entre 2 e 255 letras; '
            }

            if (this.state.NomeMunicipioEstrangeiro === null
                && this.state.Nacionalidade.toLowerCase() != 'brasileiro'
                && this.state.Nacionalidade.toLowerCase() != 'brasileira'
            ) {
                errors += 'Naturalidade - Município Estrangeiro é obrigatório; '
            } else if (
                (this.state.NomeMunicipioEstrangeiro.length < 2 || this.state.NomeMunicipioEstrangeiro.length > 255)
                && this.state.Nacionalidade.toLowerCase() != 'brasileiro'
                && this.state.Nacionalidade.toLowerCase() != 'brasileira'
            ) {
                errors += 'Naturalidade - Município Estrangeiro deve ter entre 2 e 255 dígitos; ';
            }

            if (this.state.DataNascimento === '') {
                errors += 'Data de Nascimento é obrigatória; '
            }

            if (this.state.RGNumero === ''
                && (this.state.Nacionalidade.toLowerCase() === 'brasileiro'
                    || this.state.Nacionalidade.toLowerCase() === 'brasileira'
                )
            ) {
                errors += 'Número de Documento de Identificação é obrigatório; '
            } else if (this.state.RGNumero.length < 4 || this.state.RGNumero.length > 15
                && (this.state.Nacionalidade.toLowerCase() === 'brasileiro'
                    || this.state.Nacionalidade.toLowerCase() === 'brasileira'
                )
            ) {
                errors += 'Número de Documento de Identificação deve ter entre 6 e 15 dígitos; ';
            }
            if (this.state.RGUF == ''
                && (this.state.Nacionalidade.toLowerCase() === 'brasileiro'
                    || this.state.Nacionalidade.toLowerCase() === 'brasileira'
                )) {
                errors += 'UF do Documento de Identificação é obrigatório; '
            }

            if (this.state.OutroDocumentoIdentificacaoIdentificador === null
                && this.state.Nacionalidade.toLowerCase() != 'brasileiro'
                && this.state.Nacionalidade.toLowerCase() != 'brasileira'
            ) {
                errors += 'Documento de Identificação - Identificador é obrigatório; '
            } else if (
                (this.state.OutroDocumentoIdentificacaoIdentificador.length < 2 || this.state.OutroDocumentoIdentificacaoIdentificador.length > 255)
                && this.state.Nacionalidade.toLowerCase() != 'brasileiro'
                && this.state.Nacionalidade.toLowerCase() != 'brasileira'
            ) {
                errors += 'Documento de Identificação - Identificador deve ter entre 2 e 255 dígitos; ';
            }

            if (this.state.OutroDocumentoIdentificacaoTipoDocumento === null
                && this.state.Nacionalidade.toLowerCase() != 'brasileiro'
                && this.state.Nacionalidade.toLowerCase() != 'brasileira'
            ) {
                errors += 'Documento de Identificação - Tipo é obrigatório; '
            } else if (
                (this.state.OutroDocumentoIdentificacaoTipoDocumento.length < 2 || this.state.OutroDocumentoIdentificacaoTipoDocumento.length > 255)
                && this.state.Nacionalidade.toLowerCase() != 'brasileiro'
                && this.state.Nacionalidade.toLowerCase() != 'brasileira'
            ) {
                errors += 'Documento de Identificação - Tipo deve ter entre 2 e 255 dígitos; ';
            }

            if (this.state.NaturalidadeUF == ''
                && (this.state.Nacionalidade.toLowerCase() === 'brasileiro'
                    || this.state.Nacionalidade.toLowerCase() === 'brasileira'
                )
            ) {
                errors += 'UF de Naturalidade é obrigatório; '
            }
            if (this.state.NaturalidadeCodigoMunicipio == ''
                && (this.state.Nacionalidade.toLowerCase() === 'brasileiro'
                    || this.state.Nacionalidade.toLowerCase() === 'brasileira'
                )
            ) {
                errors += 'Código do Município de Naturalidade é obrigatório; '
            }
            if (this.state.RGOrgaoExpedidor != '') {
                if (this.state.RGOrgaoExpedidor.length < 2 || this.state.RGOrgaoExpedidor.length > 255) {
                    errors += 'Orgão Expedidor do RG deve ter entre 2 e 255 letras; '
                }
            }
            if (this.state.Sexo == '') {
                errors += 'Sexo é obrigatório; '
            }
            if (this.state.SexoMae === '') {
                errors += 'Sexo Mãe é obrigatório; '
            }
            if (this.state.SexoPai === '') {
                errors += 'Sexo Pai é obrigatório; '
            }
            if (this.state.NomeMae === '') {
                errors += 'Nome da Mãe é obrigatório; '
            } else if (this.state.NomeMae.length < 2 || this.state.NomeMae.length > 255) {
                errors += 'Nome da Mãe deve ter entre 2 e 255 letras; '
            }
            // if (this.state.NomePai === '') {
            //     errors += 'Nome do Pai é obrigatório; '
            // } else 
            if (this.state.NomePai.length > 255) { //this.state.NomePai.length < 2 || 
                errors += 'Nome do Pai deve ter entre 2 e 255 letras; '
            }
        } else if (currentStep == 2) {
            if (this.state.Modalidade === '') {
                errors += 'Modalidade de curso é obrigatório; '
            }
            if (this.state.Grau === '') {
                errors += 'Grau de curso é obrigatório; '
            }
            if (this.state.TituloConferido === '') {
                errors += 'Título Conferido é obrigatório; '
            }
            if (this.state.ReconhecimentoID === '' || this.state.ReconhecimentoID === 0) {
                errors += 'Reconhecimento de curso é obrigatório; '
            }
            if (this.state.NomeCurso === '') {
                errors += 'Nome do Curso é obrigatório; '
            } else if (this.state.NomeCurso.length < 2 || this.state.NomeCurso.length > 255) {
                errors += 'Nome do Curso deve ter entre 2 e 255 letras; '
            }
            if (this.state.AutorizacaoCurso === '' || this.state.AutorizacaoCurso == null || this.state.AutorizacaoCurso == undefined || this.state.AutorizacaoCurso === 0) {
                errors += 'Autorização do Curso é obrigatório; '
            }
        } else if (currentStep == 3) {
            if (this.state.LivroID === '') {
                errors += 'Livro de Registro é obrigatório; '
            }
            if (this.state.Processo === '') {
                errors += 'Processo é obrigatório; '
            } else if (this.state.Processo.length < 2 || this.state.Processo.length > 255) {
                errors += 'Processo deve ter entre 2 e 255 dígitos numéricos. Simbolos ".", "/", "-" são permitidos.; '
            }
            // if (this.state.NumeroFolhaDoDiploma === '') {
            //     errors += 'Numero da Folha do Diploma é obrigatório; '
            // } else if (this.state.NumeroFolhaDoDiploma.length < 1 || this.state.NumeroFolhaDoDiploma.length > 5) {
            //     errors += 'Numero da Folha do Diploma deve ter entre 1 e 5 dígitos numéricos; '
            // }
            // if (this.state.NumeroSequenciaDoDiploma === '') {
            //     errors += 'Numero Sequência do Diploma é obrigatório; '
            // } else if (this.state.NumeroSequenciaDoDiploma.length < 1 || this.state.NumeroSequenciaDoDiploma.length > 5) {
            //     errors += 'Numero Sequência do Diploma deve ter entre 1 e 5 dígitos numéricos; '
            // }
            if (this.state.DataRegistro === '') {
                errors += 'Data de Registro é obrigatória; '
            }
            if (this.state.DataExpedicao === '') {
                errors += 'Data de Expedição é obrigatória; '
            }
            if (this.state.DataColacao === '') {
                errors += 'Data de Colação é obrigatória; '
            }
            if (this.state.responsavelRegistro == null ||
                this.state.responsavelRegistro.Nome == null || this.state.responsavelRegistro.Nome == "" ||
                this.state.responsavelRegistro.CPF == null || this.state.responsavelRegistro.CPF == "" ||
                this.state.responsavelRegistro.IDouNumeroMatricula == null || this.state.responsavelRegistro.IDouNumeroMatricula == "") {
                errors += 'Responsável pelo Registro não informado; '
            }
        } else if (currentStep == 4) {
            if (this.state.SituacaoENADE === '') {
                errors += 'Situação ENADE é obrigatório; '
            }
            if (this.state.DataEmissaoHistorico === '') {
                errors += 'Data de Emissão do Histórico é obrigatória; '
            }
        } else if (currentStep == 5) {
            if (this.state.FormaAcesso === '') {
                errors += 'Forma de Acesso é obrigatório; '
            }            
            if (this.state.DataIngresso === '' || this.state.DataIngresso == null) {
                errors += 'Data de Ingresso é obrigatória; '
            }
            if (this.state.DataConclusao === '' || this.state.DataConclusao == null) {
                errors += 'Data de Conclusão é obrigatória; '
            }
        }

        if (errors.trim().length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Verfique campos obrigatórios!',
                text: `${errors.trim()}`,
            });
        } else {
            currentStep = currentStep >= 1 && currentStep < this.state.stepFinal ? currentStep + 1 : currentStep
            this.setState({ currentStep: currentStep })
            const inicio = document.getElementById("registroNovoForm")
            if (inicio) {
                window.scrollTo(0, inicio.offsetTop);
            };
        }
    }
    _prev() {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({ currentStep: currentStep })
        const inicio = document.getElementById("registroNovoForm")
        if (inicio) {
            window.scrollTo(0, inicio.offsetTop);
        };
    }
    get previousButton() {
        let currentStep = this.state.currentStep
        if (currentStep !== 1) {
            return (
                <Button
                    className="btn btn-secondary float-right registro-novo-botoes-etapas"
                    type="button"
                    onClick={this._prev}>
                    Anterior
                </Button>
            )
        }
        return <></>
    }
    get nextButton() {
        if (this.state.currentStep < this.state.stepFinal) {
            return (
                <Button
                    className="btn btn-primary float-right registro-novo-botoes-etapas"
                    type="button"
                    onClick={this._next}>
                    Próximo
                </Button>
            )
        }
        return <></>
    }
    get registrarButton() {
        if (this.state.currentStep === this.state.stepFinal) {
            return (
                <Button
                    className="float-right registro-novo-botoes-etapas"
                    variant="success"
                    type="submit">
                    Registrar
                </Button>
            )
        }
        return <></>
    }
    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false
            }
        }

        return JSON.stringify(obj) === JSON.stringify({})
    }
    render() {
        return (
            <Formik
                initialValues={{
                    Nome: this.state.Nome,
                    NomeSocial: this.state.NomeSocial,
                    CPF: this.state.CPF,
                    Sexo: this.state.Sexo,
                    DataNascimento: this.state.DataNascimento,
                    Nacionalidade: this.state.Nacionalidade,
                    RGNumero: this.state.RGNumero,
                    RGUF: this.state.RGUF,
                    RGOrgaoExpedidor: this.state.RGOrgaoExpedidor,
                    OutroDocumentoIdentificacaoTipoDocumento: this.state.OutroDocumentoIdentificacaoTipoDocumento,
                    OutroDocumentoIdentificacaoIdentificador: this.state.OutroDocumentoIdentificacaoIdentificador,
                    NomeMunicipioEstrangeiro: this.state.NomeMunicipioEstrangeiro,
                    NaturalidadeUF: this.state.NaturalidadeUF,
                    NaturalidadeCodigoMunicipio: this.state.NaturalidadeCodigoMunicipio,
                    NomeCurso: this.state.NomeCurso,
                    Modalidade: this.state.Modalidade,
                    Grau: this.state.Grau,
                    CursoFormacao: this.state.CursoFormacao,
                    TituloConferido: this.state.TituloConferido,
                    ReconhecimentoID: this.state.ReconhecimentoID,
                    Habilitacao: this.state.Habilitacao,
                    NomeMae: this.state.NomeMae,
                    NomePai: this.state.NomePai,
                    SexoMae: this.state.SexoMae,
                    SexoPai: this.state.SexoPai,
                    SituacaoENADE: this.state.SituacaoENADE,
                    DataEmissaoHistorico: this.state.DataEmissaoHistorico,
                    FormaAcesso: this.state.FormaAcesso,
                    DataIngresso: this.state.DataIngresso,
                    LivroID: this.state.LivroID,
                    Processo: this.state.Processo,
                    // NumeroFolhaDoDiploma: this.state.NumeroFolhaDoDiploma,
                    // NumeroSequenciaDoDiploma: this.state.NumeroSequenciaDoDiploma,
                    DataColacao: this.state.DataColacao,
                    DataExpedicao: this.state.DataExpedicao,
                    DataRegistro: this.state.DataRegistro,
                    DataConclusao: this.state.DataConclusao,
                }}
                validate={(values) => {
                    let errors = {}
                    if (this.state.Sexo === '') {
                        errors.Sexo = 'Sexo é obrigatório'
                    }
                    // if (this.state.RGUF === '') {
                    //     errors.RGUF = 'UF do Documento de Identificação é obrigatório'
                    // }
                    // if (this.state.NaturalidadeCodigoMunicipio === '') {
                    //     errors.NaturalidadeCodigoMunicipio = 'Código do Município de Naturalidade é obrigatório'
                    // }
                    // if (this.state.NaturalidadeUF === '') {
                    //     errors.NaturalidadeUF = 'UF de Naturalidade é obrigatório'
                    // }
                    if (this.state.Modalidade === '') {
                        errors.Modalidade = 'Modalidade de curso é obrigatório'
                    }
                    if (this.state.Grau === '') {
                        errors.Grau = 'Grau de curso é obrigatório'
                    }
                    if (this.state.ReconhecimentoID === '' || this.state.ReconhecimentoID === 0) {
                        errors.ReconhecimentoID = 'Reconhecimento de curso é obrigatório'
                    }
                    if (this.state.TituloConferido === '') {
                        errors.TituloConferido = 'Título Conferido é obrigatório'
                    }
                    if (this.state.FormaAcesso === '') {
                        errors.FormaAcesso = 'Forma de Acesso é obrigatório'
                    }
                    if (this.state.SituacaoENADE === '') {
                        errors.SituacaoENADE = 'Situação ENADE é obrigatório'
                    }
                    if (this.state.SexoMae === '') {
                        errors.SexoMae = 'Sexo Mãe é obrigatório'
                    }
                    if (this.state.SexoPai === '') {
                        errors.SexoPai = 'Sexo Pai é obrigatório'
                    }
                    if (this.state.LivroID === '') {
                        errors.LivroID = 'Livro de Registro é obrigatório'
                    }
                    if (this.state.DataNascimento === '') {
                        errors.DataNascimento = 'Data de Nascimento é obrigatória'
                    }
                    if (this.state.DataConclusao === '' || this.state.DataConclusao == null) {
                        errors.DataConclusao = 'Data de Conclusão é obrigatória'
                    }
                    if (this.state.DataRegistro === '') {
                        errors.DataRegistro = 'Data de Registro é obrigatória'
                    }
                    if (this.state.DataExpedicao === '') {
                        errors.DataExpedicao = 'Data de Expedição é obrigatória'
                    }
                    if (this.state.DataColacao === '') {
                        errors.DataColacao = 'Data de Colação é obrigatória'
                    }
                    if (this.state.DataIngresso === '' || this.state.DataIngresso == null) {
                        errors.DataIngresso = 'Data de Ingresso é obrigatória'
                    }
                    if (this.state.DataEmissaoHistorico === '') {
                        errors.DataEmissaoHistorico = 'Data de Emissão do Histórico é obrigatória'
                    }
                    if (this.state.Nome === '') {
                        errors.Nome = 'Nome é obrigatório'
                    } else if (this.state.Nome.length < 2 || this.state.Nome.length > 255) {
                        errors.Nome = 'Nome deve ter entre 2 e 255 letras'
                    }
                    if (this.state.NomeSocial != '' && this.state.NomeSocial != null && this.state.NomeSocial != undefined) {
                        if (this.state.NomeSocial.length < 2 || this.state.NomeSocial.length > 255) {
                            errors += 'Nome Social deve ter entre 2 e 255 letras; '
                        }
                    }
                    if (this.state.Nacionalidade === '') {
                        errors.Nacionalidade = 'Nacionalidade é obrigatório'
                    } else if (this.state.Nacionalidade.length < 2 || this.state.Nacionalidade.length > 255) {
                        errors.Nacionalidade = 'Nacionalidade deve ter entre 2 e 255 letras'
                    }
                    if (this.state.CPF === '') {
                        errors.CPF = 'CPF é obrigatório'
                    } else if (this.state.CPF.replace(/\D+/g, '').length !== 11) {
                        errors.CPF = 'CPF deve ter 11 dígitos numéricos'
                    }
                    // if (this.state.RGNumero === '') {
                    //     errors.RGNumero = 'Número de Documento de Identificação é obrigatório'
                    // } else if (this.state.RGNumero.length < 6 || this.state.RGNumero.length > 11) {
                    //     errors.RGNumero = 'Número de Documento de Identificação deve ter entre 6 e 11 dígitos'
                    // }
                    // if (this.state.RGOrgaoExpedidor === '') {
                    //     errors.RGOrgaoExpedidor = 'Orgão Expedidor do RG é obrigatório'
                    // } else if (this.state.RGOrgaoExpedidor.length < 2 || this.state.RGOrgaoExpedidor.length > 255) {
                    //     errors.RGOrgaoExpedidor = 'Orgão Expedidor do RG deve ter entre 2 e 255 letras'
                    // }
                    if (this.state.NomeCurso === '') {
                        errors.NomeCurso = 'Nome do Curso é obrigatório'
                    } else if (this.state.NomeCurso.length < 2 || this.state.NomeCurso.length > 255) {
                        errors.NomeCurso = 'Nome do Curso deve ter entre 2 e 255 letras'
                    }
                    if (this.state.NomeMae === '') {
                        errors.NomeMae = 'Nome da Mãe é obrigatório'
                    } else if (this.state.NomeMae.length < 2 || this.state.NomeMae.length > 255) {
                        errors.NomeMae = 'Nome da Mãe deve ter entre 2 e 255 letras'
                    }
                    // if (this.state.NomePai === '') {
                    //     errors.NomePai = 'Nome do Pai é obrigatório'
                    // } else 
                    if (this.state.NomePai.length > 255) { //this.state.NomePai.length < 2 || 
                        errors.NomePai = 'Nome do Pai deve ter entre 2 e 255 letras'
                    }
                    if (this.state.Processo === '') {
                        errors.Processo = 'Processo é obrigatório'
                    } else if (this.state.Processo.length < 2 || this.state.Processo.length > 255) {
                        errors.Processo = 'Processo deve ter entre 2 e 255 dígitos numéricos. Simbolos ".", "/", "-" são permitidos.'
                    }
                    // if (this.state.NumeroFolhaDoDiploma === '') {
                    //     errors.NumeroFolhaDoDiploma = 'Numero da Folha do Diploma é obrigatório'
                    // } else if (this.state.NumeroFolhaDoDiploma.length < 1 || this.state.NumeroFolhaDoDiploma.length > 5 ) {
                    //     errors.NumeroFolhaDoDiploma = 'Numero da Folha do Diploma deve ter entre 1 e 5 dígitos numéricos'
                    // }
                    // if (this.state.NumeroSequenciaDoDiploma === '') {
                    //     errors.NumeroSequenciaDoDiploma = 'Numero Sequência do Diploma é obrigatório'
                    // } else if (this.state.NumeroSequenciaDoDiploma.length < 1 || this.state.NumeroSequenciaDoDiploma.length > 5) {
                    //     errors.NumeroSequenciaDoDiploma = 'Numero Sequência do Diploma deve ter entre 1 e 5 dígitos numéricos'
                    // }
                    console.log(errors)
                    if (!this.isEmpty(errors)) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Existem campos obrigatórios não preenchidos!',
                        })
                    }
                    return errors
                }}
                onSubmit={({ }) => {
                    this.salvarRegistroAPI()
                    this.setState({ isSubmitting: false })
                }} >
                {({ touched, errors, isSubmitting }) => (
                    <Form
                        name="registroNovoForm"
                        id="registroNovoForm">
                        <RegistroNovoCamposDiplomado
                            ID={this.state.ID}
                            Nome={this.state.Nome}
                            NomeSocial={this.state.NomeSocial}
                            Sexo={this.state.Sexo}
                            Nacionalidade={this.state.Nacionalidade}
                            CPF={this.state.CPF}
                            DataNascimento={this.state.DataNascimento}
                            NaturalidadeCodigoMunicipio={this.state.NaturalidadeCodigoMunicipio}
                            NaturalidadeNomeMunicipio={this.state.NaturalidadeNomeMunicipio?.trim()}
                            NaturalidadeUF={this.state.NaturalidadeUF}
                            NomeMunicipioEstrangeiro={this.state.NomeMunicipioEstrangeiro}
                            RGNumero={this.state.RGNumero}
                            RGOrgaoExpedidor={this.state.RGOrgaoExpedidor}
                            RGUF={this.state.RGUF}
                            OutroDocumentoIdentificacaoIdentificador={this.state.OutroDocumentoIdentificacaoIdentificador}
                            OutroDocumentoIdentificacaoTipoDocumento={this.state.OutroDocumentoIdentificacaoTipoDocumento}
                            currentStep={this.state.currentStep}
                            ufs={this.state.ufs}
                            municipios={this.state.municipios}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeDate={this.handleInputChangeDate}
                            touched={touched}
                            errors={errors}
                        />
                        <RegistroNovoCamposDadosCurso
                            CursoID={this.state.CursoID}
                            NomeCurso={this.state.NomeCurso}
                            Modalidade={this.state.Modalidade}
                            Grau={this.state.Grau}
                            CursoFormacao={this.state.CursoFormacao}
                            TituloConferido={this.state.TituloConferido}
                            ReconhecimentoID={this.state.ReconhecimentoID}
                            Habilitacao={this.state.Habilitacao}
                            CodigoCursoEMEC={this.state.CodigoCursoEMEC}
                            EnderecoCurso={this.state.EnderecoCurso}
                            AutorizacaoCurso={this.state.AutorizacaoCurso}
                            currentStep={this.state.currentStep}
                            cursoDados={this.state.cursoDados}
                            cursosGraus={this.state.cursosGraus}
                            cursoFormacoes={this.state.cursoFormacoes}
                            cursoTitulacoes={this.state.cursoTitulacoes}
                            cursoReconhecimentos={this.state.cursoReconhecimentos}
                            handleInputChange={this.handleInputChange}
                            touched={touched}
                            errors={errors}
                        />
                        <RegistroNovoCamposLivroRegistro
                            currentStep={this.state.currentStep}
                            livros={this.state.livros}
                            LivroRegistro={this.state.LivroRegistro}
                            LivroID={this.state.LivroID}
                            Processo={this.state.Processo}
                            // NumeroFolhaDoDiploma={this.state.NumeroFolhaDoDiploma}
                            // NumeroSequenciaDoDiploma={this.state.NumeroSequenciaDoDiploma}
                            DataColacao={this.state.DataColacao}
                            DataExpedicao={this.state.DataExpedicao}
                            DataRegistro={this.state.DataRegistro}
                            responsavelRegistro={this.state.responsavelRegistro}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeDate={this.handleInputChangeDate}
                            touched={touched}
                            errors={errors}
                        />
                        <RegistroNovoCamposFiliacao
                            currentStep={this.state.currentStep}
                            NomeMae={this.state.NomeMae}
                            SexoMae={this.state.SexoMae}
                            NomePai={this.state.NomePai}
                            SexoPai={this.state.SexoPai}
                            handleInputChange={this.handleInputChange}
                            touched={touched}
                            errors={errors}
                        />
                        <RegistroNovoCamposHistoricoEscolar
                            currentStep={this.state.currentStep}
                            SituacaoAlunoHistorico={this.state.SituacaoAlunoHistorico}
                            DataEmissaoHistorico={this.state.DataEmissaoHistorico}
                            SituacaoENADEIngressante={this.state.SituacaoENADEIngressante}
                            SituacaoENADEConcluinte={this.state.SituacaoENADEConcluinte}
                            SituacaoENADE={this.state.SituacaoENADE}
                            CargaHorariaCursoIntegralizada={this.state.CargaHorariaCursoIntegralizada}
                            CargaHorariaCurso={this.state.CargaHorariaCurso}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeDate={this.handleInputChangeDate}
                            touched={touched}
                            errors={errors}
                        />
                        <RegistroNovoCamposIngressoCurso
                            currentStep={this.state.currentStep}
                            DataConclusao={this.state.DataConclusao}
                            DataIngresso={this.state.DataIngresso}
                            TipoIngressoDescricao={this.state.TipoIngressoDescricao}
                            FormaAcesso={this.state.FormaAcesso}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeDate={this.handleInputChangeDate}
                            touched={touched}
                            errors={errors}
                        />
                        <RegistroNovoCamposAdicionais
                            currentStep={this.state.currentStep}
                            handleInputChange={this.handleInputChange}
                            touched={touched}
                            errors={errors}
                            iesEmissora={this.state.iesEmissora}
                            iesRegistradora={this.state.iesRegistradora}
                        />
                        <RegistroAssinaturas
                            assinaturasDiploma={this.state.assinaturasDiploma}
                            assinaturaDadosRegistro={this.state.assinaturaDadosRegistro}
                            currentStep={this.state.currentStep}
                            setAssinanteDadosRegistro={this.setAssinanteDadosRegistroP}
                            setAssinanteDiploma={this.setAssinanteDiplomaP}
                            removeAssinanteDadosRegistro={this.removeAssinanteDadosRegistro}
                            removeAssinanteDiploma={this.removeAssinanteDiploma}
                            updateAssinanteDiploma={this.updateAssinanteDiploma}
                            updateAssinanteDadosRegistro={this.updateAssinanteDadosRegistro}
                        />
                        <Row>
                            <Col>
                                <Link
                                    className="btn btn-warning registro-novo-botoes-etapas"
                                    to="/registros">
                                    Cancelar
                                </Link>
                                {this.registrarButton}
                                {this.nextButton}
                                {this.previousButton}
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }
}

const mapStateToProps = (state) => ({
    ufs: state.registros.ufs,
    municipios: state.registros.municipios,
    estudante: state.registros.estudante,
    loading: state.registros.loading,
    error: state.registros.error,
    curso: state.cursos.curso,
    cursosGraus: state.cursos.cursosGraus,
    cursoFormacoes: state.cursos.cursoFormacoes,
    cursoTitulacoes: state.cursos.cursoTitulacoes,
    cursoReconhecimentos: state.cursos.cursoReconhecimentos,
    iesEmissora: state.ies.iesEmissora,
    iesRegistradora: state.ies.iesRegistradora,
    livros: state.livros.livros,
    enade: state.registros.enade,
})

const mapDispatchToProps = (dispatch) => ({
    onCreatePressed: (dadosRegistro) => dispatch(addNovoRegistroRequest(dadosRegistro)),
    onReloadMunicipios: (uf) => dispatch(loadMunicipios(uf)),
    dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistroNovoForm)